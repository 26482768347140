<template>
  <div class="fullheight has-background-white-bis">
    <AppNavigation />
    <AdminBreadcrumb />
    <div class="row">
      <div class="padding-y-3 padding-y-6-tablet">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import AppNavigation from '@/components/navigation/AppNavigation'
import AdminBreadcrumb from '@/components/navigation/AdminBreadcrumb'

export default {
  components: {
    AppNavigation,
    AdminBreadcrumb
  },
  mounted () {
    this.$store.commit('setApp', 'manage')
  }
}
</script>
