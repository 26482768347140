<template>
  <div>
    <AppNavigation />
    <AdminBreadcrumb />
    <slot />
  </div>
</template>

<script>

import AppNavigation from '@/components/navigation/AppNavigation'
import AdminBreadcrumb from '@/components/navigation/AdminBreadcrumb'

export default {
  components: {
    AppNavigation,
    AdminBreadcrumb
  },
  mounted () {
    this.$store.commit('setApp', 'manage')
  }
}

</script>
