import Vue from 'vue'
import Buefy from 'buefy'
import VeeValidate from 'vee-validate'
import VueMoment from 'vue-moment'
import VueCurrencyFilter from 'vue-currency-filter'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/scss/app.scss'
import '@fortawesome/fontawesome-pro/js/all.min.js'
import 'vue-loading-overlay/dist/vue-loading.css'

import DefaultLayout from '@/layouts/Default.vue'
import ExternalLayout from '@/layouts/External.vue'
import ManageLayout from '@/layouts/Manage.vue'
import ManageSingleLayout from '@/layouts/ManageSingle.vue'

import vueScrollto from 'vue-scrollto'
import Vue2Filters from 'vue2-filters'

import AutocompleteSingle from '@/components/fields/AutocompleteSingle'

import Loading from 'vue-loading-overlay'

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.use(vueScrollto)
Vue.use(Vue2Filters)

Vue.use(VeeValidate)
Vue.use(VueMoment)
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})
Vue.component('default-layout', DefaultLayout)
Vue.component('external-layout', ExternalLayout)
Vue.component('manage-layout', ManageLayout)
Vue.component('manage-single-layout', ManageSingleLayout)

Vue.component('autocomplete-single', AutocompleteSingle)

Vue.component('loading', Loading)

Vue.config.productionTip = false

store.dispatch('init').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

Vue.mixin({
  computed: {
    i_r () {
      return 2 // imperiaerl rounding
    },
    m_r () {
      return 2 // metric rounding
    }
  },
  methods: {
    numberFormat (value, decimal = 2, hasZeros = false) {
      // HasZeros = Return zeros instead null
      if (value) {
        return parseFloat(value).toFixed(decimal)
      } else {
        if (hasZeros) {
          return parseFloat(0).toFixed(decimal)
        } else {
          return null
        }
      }
    },
    inchmm (value, decimal = 2) {
      // value = this.astmRound((value * 25.4), decimal)
      // return parseFloat(this.astmRound((value * 25.4), (decimal + 1))).toFixed(decimal)
      return parseFloat(this.astmRound((value * 25.4), decimal)).toFixed(decimal)
    },
    inchmm2 (value, decimal = 2) {
      value = value * 645.16
      return parseFloat(value).toFixed(decimal)
    },
    psiMpa (value, decimal = 2) {
      // convert psi to MPa divide by 145.0377
      value = value / 145.0377
      return parseFloat(value).toFixed(decimal)
    },
    kips (value, decimal = 2) {
      // convert kips to kn multiply by 4.4482216152605
      value = value * 4.4482216152605
      return parseFloat(value).toFixed(decimal)
    },
    lbkg (value, decimal = 2) {
      // convert lb/ft to kg/m multiply by 1.35581795
      value = value * 1.35581795
      return parseFloat(value).toFixed(decimal)
    },
    astmRound (value, precision, mode = 'ROUND_HALF_EVEN') {
      // https://pawelgrzybek.com/rounding-and-truncating-numbers-in-javascript/
      precision |= 0
      return Math.round(value * Math.pow(10, precision)) / (Math.pow(10, precision))

      // //  discuss at: http://locutus.io/php/round/
      // // original by: Philip Peterson
      // //  revised by: Onno Marsman (https://twitter.com/onnomarsman)
      // //  revised by: T.Wild
      // //  revised by: Rafał Kukawski (http://blog.kukawski.pl)
      // //    input by: Greenseed
      // //    input by: meo
      // //    input by: William
      // //    input by: Josep Sanz (http://www.ws3.es/)
      // // bugfixed by: Brett Zamir (http://brett-zamir.me)
      // //      note 1: Great work. Ideas for improvement:
      // //      note 1: - code more compliant with developer guidelines
      // //      note 1: - for implementing PHP constant arguments look at
      // //      note 1: the pathinfo() function, it offers the greatest
      // //      note 1: flexibility & compatibility possible
      // //   example 1: round(1241757, -3)
      // //   returns 1: 1242000
      // //   example 2: round(3.6)
      // //   returns 2: 4
      // //   example 3: round(2.835, 2)
      // //   returns 3: 2.84
      // //   example 4: round(1.1749999999999, 2)
      // //   returns 4: 1.17
      // //   example 5: round(58551.799999999996, 2)
      // //   returns 5: 58551.8

      // var m, f, isHalf, sgn // helper variables
      // // making sure precision is integer
      // precision |= 0
      // m = Math.pow(10, precision)
      // value = value * m
      // // sign of the number
      // sgn = (value > 0) | -(value < 0)
      // isHalf = value % 1 === 0.5 * sgn
      // f = Math.floor(value)

      // if (isHalf) {
      //   switch (mode) {
      //     case 'ROUND_HALF_DOWN':
      //       // rounds .5 toward zero
      //       value = f + (sgn < 0)
      //       break
      //     case 'ROUND_HALF_EVEN':
      //       // rouds .5 towards the next even integer
      //       value = f + (f % 2 * sgn)
      //       break
      //     case 'ROUND_HALF_ODD':
      //       // rounds .5 towards the next odd integer
      //       value = f + !(f % 2)
      //       break
      //     default:
      //       // rounds .5 away from zero
      //       value = f + (sgn > 0)
      //   }
      // }
      // return (isHalf ? value : Math.round(value)) / m
    },
    orderCouplilngVetor (couplingVetor) {
      // OrderBy Name
      couplingVetor = this.orderBy(couplingVetor, 'name')
      // Found the std coupling. STD = Uppercase
      const stdCoupling = couplingVetor.find(c => c.name === 'STD')

      if (stdCoupling) {
        couplingVetor.splice(couplingVetor.indexOf(stdCoupling), 1)
        // Add the STD again
        couplingVetor.unshift(stdCoupling)
      }

      return couplingVetor
    }
  }
})
