<template>
  <div>
    <AppNavigation v-if="$route && !['login'].includes($route.name)" />
    <slot />
  </div>
</template>
<script>
import AppNavigation from '@/components/navigation/AppNavigation'

export default {
  components: {
    AppNavigation
  },
  data () {
    return {}
  }
}
</script>
