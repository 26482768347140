<template>
            <form id="login_form_wrapper" class="form" @submit.prevent="submit">
              <div id="login_form">
                <div v-if="error" class="padding-y-3" style="padding-top: 0">
                  <b-tag type="is-warning">
                    {{ error }}
                  </b-tag>
                </div>
                <b-field v-show="!loginResponse" position="is-centered">
                  <b-input
                    id="username"
                    v-model="username"
                    autofocus
                    expanded
                    placeholder="Email"
                    type="text"
                    icon="user" />
                </b-field>
                <b-field v-show="!loginResponse" position="is-centered">
                  <b-input
                    v-model="password"
                    expanded
                    placeholder="Password"
                    type="password"
                    icon="lock" />
                  <p class="control">
                    <b-tooltip label="Reset Password" type="is-dark">
                      <b-button class="is-outlined" icon-left="redo" @click="forgotPassword()" />
                    </b-tooltip>
                  </p>
                </b-field>
                <b-field v-show="loginResponse" grouped position="is-centered">
                  <b-input
                    id="secretKey"
                    v-model="secretKey"
                    expanded
                    placeholder="Secret Key"
                    type="password"
                    icon="star" />
                </b-field>
                <div v-if="u2fMessage" class="padding-y-3" style="padding-top: 0">
                  <b-tag type="is-warning">
                    {{ u2fMessage }}
                  </b-tag>
                </div>
                <b-message type="is-danger" title="Error" :active.sync="isYubikeyMessageError" aria-close-label="Close message">
                  {{ yubikeyMessageError }}
                </b-message>
                <div v-if="showRegistration" class="column is-narrow">
                  <b-button :disabled="busy" type="is-success" size="is-medium" @click="register()">
                    Start Registration
                  </b-button>
                  <br><small>When the dongle starts blinking, press the gold disc to activate it.</small>
                  <br><small>{{ yubikeyMessage }}</small>
                </div>
              </div>
              <div v-show="!loginResponse">
                <input
                  id="login_btn"
                  class="button is-expanded is-primary"
                  type="submit"
                  value="Login">
              </div>
              <!-- <div v-show="!loginResponse">
                <div class="padding-y-5">
                <b-button type="is-dark is-small has-text-uppercase" @click="forgotPassword()">
                  Reset password
                </b-button>
                </div>
              </div> -->
              <div v-show="loginResponse">
                <input
                  id="change_btn"
                  class="button is-expanded is-primary"
                  value="Change User"
                  @click="changeUser()">
              </div>
            </form>
</template>


<script>
import AccountApi from '@/apis/AccountApi'
import UsersApi from '@/apis/UsersApi'
import EmailsApi from '@/apis/EmailsApi'
import Moment from 'moment'
const { Yubico } = require('yubico-node')
const SHA = require('sha.js')

export default {
  name: 'Login',
  props: {
    closeModal: {
      type: Boolean,
      default: false
    }
  },  
  data () {
    return {
      username: null,
      password: null,
      secretKey: null,
      error: null,
      u2fMessage: null,
      busy: false,
      yubikeyMessage: null,
      yubikeyMessageError: null,
      showRegistration: false,
      showForgotPassword: false,
      gLength: 9,
      characters: [
        {
          name: 'Lowercase',
          value: 'abcdefghijklmnopqrstuvwxyz',
          checked: true
        },
        {
          name: 'Uppercase',
          value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
          checked: true
        },
        {
          name: 'Numbers',
          value: '0123456789',
          checked: true
        },
        {
          name: 'Special Characters',
          value: '_-+=)(*&%$#@!`~',
          checked: false
        }
      ],
      notificationEmail: {
        emailTo: {
          email: null,
          name: null
        },
        subject: null,
        textPart: null,
        body: null
      }
    }
  },
  computed: {
    profile () {
      // return this.$store.commit("setProfile", "guest");
      return this.$store.state.profile
    },
    loginResponse: {
      get () {
        return this.$store.state.loginResponse
      },
      set (val) {
        this.$store.commit('setLoginResponse', val)
      }
    },
    isYubikeyMessageError: {
      get () {
        if (this.yubikeyMessageError) {
          return true
        } else {
          return false
        }
      },
      set (val) {
        return val
      }
    }
  },
  watch: {
    async 'loginResponse' (response) {
      if (response) {
        this.$nextTick(() => {
          // The whole view is rendered, so I can safely access or query
          // the DOM. ¯\_(ツ)_/¯
          document.getElementById('secretKey').focus()
        })
        if (this.secretKey) {
          const loadingComponent = this.$buefy.loading.open({ container: null })
          this.yubikeyMessageError = null

          const yubic = new Yubico({
            clientId: process.env.VUE_APP_YUBICO_CLIENT_ID,
            secret: process.env.VUE_APP_YUBICO_SECRET,
            sl: 100,
            timeout: 30
          })

          try {
            var res = await yubic.verify(this.secretKey)

            if (res.getStatus() === 'OK') {
              if (response.profile.yubikeyIdentity === res.getPublicId()) { // Right User
                await this.$store.dispatch('refreshLoginU2F', this.loginResponse)
                this.loginResponse = null
                this.u2fMessage = null
                this.$emit('loginSuccess')
                if(this.closeModal) {
                  this.$parent.close()
                }                
              } else { // Wrong User
                if (response.profile.yubikeyIdentity) { // User already ahs a Yubikey? Maybe somebody is trying hack it
                  this.yubikeyMessageError = 'Invalid dongle.'
                  this.secretKey = null
                  document.getElementById('secretKey').focus()
                } else {
                  try { // Save the Yubikey for this user
                    // Check Serial Yubikey used
                    var yubikeyUser = await UsersApi.getUserByYubikeySerial(res.getSerialNumber()) // If an existing user has used the same dongle, function throw a error
                    // var maskid = yubikeyUser.username.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c)
                    this.yubikeyMessageError = 'This dongle is already assigned to ' + yubikeyUser.firstName + ' ' + yubikeyUser.lastName.charAt(0)
                    this.secretKey = null
                    document.getElementById('secretKey').focus()
                  } catch (err) {
                    try {
                      // The Serial is free to use
                      response.profile.yubikeyIdentity = res.getPublicId()
                      response.profile.yubikeySerial = res.getSerialNumber()
                      response.profile.u2fKeyRegistration = JSON.stringify(res)

                      // Lets save the Yubikey
                      await UsersApi.updateUserYubikey(response.profile.id, response.profile)
                      // Go ahead with Login
                      await this.$store.dispatch('refreshLoginU2F', this.loginResponse)
                      this.loginResponse = null
                      this.u2fMessage = null
                      this.$emit('loginSuccess')
                      if(this.closeModal) {
                        this.$parent.close()
                      }                      
                    } catch (err) {
                      this.yubikeyMessageError = err
                    }
                  }
                }
              }
            } else {
              // Maybe this will never happens because this plugin thrwo an error status different "OK"
              this.yubikeyMessageError = 'Dongle validation expired. Please try again.'
              this.secretKey = null
              document.getElementById('secretKey').focus()
            }
          } catch (e) {
            this.yubikeyMessageError = e
          } finally {
            loadingComponent.close()
          }
        } else {
          this.u2fMessage = 'Insert and tap your dongle'
          document.getElementById('secretKey').focus()
        }
      }
    }
  },
  methods: {
    async register () {
      if (window.u2f && window.u2f.register) {
        await AccountApi.getSecretKeyRegister().then(registerRequest => {
          this.yubikeyMessage = 'Insert and tap your dongle'
          this.busy = true
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })

          window.u2f.register(registerRequest.data.appId, [registerRequest.data], [], async registerResponse => {
            let registerPackage = {
              secretKey: registerRequest.data,
              registerResponse: registerResponse
            }

            await AccountApi.postSecretKeyRegister(registerPackage).then(async u2fRegistration => {
              if (u2fRegistration.successful) {
                this.yubikeyMessage = 'The hardware key has been registered'
                let user = this.loginResponse.profile
                user.u2fPublicKey = u2fRegistration.publicKey
                user.u2fKeyHandle = u2fRegistration.keyHandle
                await UsersApi.updateUser(user.id, user)
              } else {
                this.yubikeyMessage = JSON.stringify(u2fRegistration)
                console.error(u2fRegistration)
              }
            })

            this.busy = false
            loadingComponent.close()
          })
        })
      } else {
        this.yubikeyMessage = 'U2F is not supported'
      }
    },
    async forgotPassword () {
      try {
        var userVerified = await UsersApi.getUserByUsername(this.username)
        if (userVerified.enabled) {
          this.$buefy.dialog.confirm({
            title: 'Reset Password',
            message: 'An email will be sent to ' + this.username + ' with reset instructions. Your password will not be reset until you choose a new one using the link in the email.',
            confirmText: 'Send Email',
            onConfirm: async () => {
              var hashPasswordDate = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
              var hashPassword = SHA('sha256').update(userVerified.username + hashPasswordDate).digest('hex')
              userVerified.passwordHash = hashPassword
              userVerified.passwordHashDate = hashPasswordDate
              await UsersApi.updateUserForgotPassword(userVerified.id, userVerified)

              let projectURL = process.env.VUE_APP_WEB_BASE + '/login/recovery/' + userVerified.username + '/' + hashPassword
              let subject = 'New password request for ' + userVerified.username
              let body = userVerified.firstName + ', a password reset has been requested for your account.' +
                '<p><strong>Please follow the link below to choose a new password:</strong><br>' +
                '<a href="' + projectURL + '">' + projectURL + '</a></p><br><br>' +
                '<p><em>Disregard this email if you did not request a password reset. Your password has not be changed.</em></p>'

              await this.sendEmailToUser(subject, body, userVerified).then((mailResult) => {
                if (mailResult.Status !== undefined) {
                  if (mailResult.Status === 'success') {
                    // Do something?
                  } else {
                    console.log('#201 Error sending email:' + mailResult.ErrorMessage)
                  }
                } else {
                  console.log('#202 Error sending email:' + JSON.stringify(mailResult.ErrorMessage))
                }
              })

              this.$buefy.toast.open('Password reset email sent')
            }
          })
        } else {
          this.error = 'This user is not active.'
        }
      } catch (err) {
        this.error = 'Enter email address'
      }
    },
    async submit () {
      try {
        this.yubikeyMessageError = null
        this.error = null
        this.u2fMessage = null
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
          secretKey: this.secretKey
        })
        if(this.profile !== 'guest'){ // if user does not have the Yubikey set, do the login
          this.$emit('loginSuccess')
          if(window.sessionStorage.getItem('redirectURL')) {
            this.$router.push(window.sessionStorage.getItem('redirectURL'))
            window.sessionStorage.setItem('redirectURL', '/')
          }
          if(this.closeModal) {
            this.$parent.close()
          }
        }
        // this.$router.push({ name: "home" });
      } catch (err) {
        console.error(err)
        this.error = err.response.data.message
      }
    },
    changeUser () {
      this.loginResponse = null
      this.u2fMessage = null
      this.yubikeyMessageError = null
      // this.$refs.username.$el.focus()
      document.getElementById('username').focus()
    },
    async sendEmailToUser (subject, body, user) {
      var env = process.env['NODE_ENV']

      this.notificationEmail.emailTo.email = user.username
      this.notificationEmail.emailTo.name = user.firstName + ' ' + user.lastName
      this.notificationEmail.subject = subject
      this.notificationEmail.body = body
      this.notificationEmail.textPart = '<p>Thank you for your business,<br>The JFE Team<br><a href="www.jfetc.com">www.jfetc.com</a>'

      if (env === 'development') {
        this.notificationEmail.emailTo.email = process.env.VUE_APP_EMAIL_DEV
      }

      return EmailsApi.sendEmail(this.notificationEmail)
    }
  }
}
</script>
