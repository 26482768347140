<template>
  <div v-if="loaded" id="app" :class="$route.meta.width === 'wide' ? 'fullwidth' : ''">
    <component :is="($route.meta.layout || 'default') + '-layout'" v-if="$route">
      <router-view :title="title" />
    </component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    currentApp () {
      return this.$store.state.app
    },
    // eslint-disable-next-line
    title () {
      document.title = 'JFE Tools • ' + this.$store.state.title
    }
  },
  async mounted () {
    await this.$store.state.profile
    this.loaded = true
  }
}

</script>
