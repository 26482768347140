<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Edit Profile
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body content padding-xy-4-tablet">
      <div class="columns">
        <div class="column">
          <b-field class="required" label="First Name" :type="{'is-danger': errors.has('firstName')}" :message="errors.first('firstName')">
            <b-input v-model="user.firstName" v-validate="'required'" name="firstName" data-vv-as="first name" />
          </b-field>
        </div>
        <div class="column">
          <b-field class="required" label="Last Name" :type="{'is-danger': errors.has('lastName')}" :message="errors.first('lastName')">
            <b-input v-model="user.lastName" v-validate="'required'" name="lastName" data-vv-as="last name" />
          </b-field>
        </div>
      </div>
      <b-field class="required" label="Username (email)" :type="{'is-danger': errors.has('username')}" :message="errors.first('username')">
        <b-input ref="username" v-model="user.username" v-validate="'required|email|validateEmail:user.username'" type="email" autocomplete="off" name="username" data-vv-as="username" />
      </b-field>
      <b-field class="required" label="Password">
        <b-input
          v-model="user.password"
          type="password"
          autocomplete="new-password"
          password-reveal />
      </b-field>
      <b-field>
        <b-button @click="cleanCache" type="is-danger is-light">Force clear user cache</b-button>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <button class="button is-transparent has-text-grey" type="button" @click="$parent.close">
            Cancel
          </button>
          <button class="button is-success" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import UsersApi from '@/apis/UsersApi'

export default {
  data () {
    return {
      loaded: false,
      user: {
        username: null,
        password: null,
        firstName: null,
        lastName: null
      }
    }
  },
  computed: {
    id () {
      return this.$store.state.profile.id
    },
    username () {
      return this.$store.state.profile.username
    }
  },
  async mounted () {
    this.user = await UsersApi.getUserById(this.id)

    this.$validator.extend('validateEmail', {
      getMessage: field => 'Email is already in use!',
      validate: async value => {
        try {
          var userVerified = await UsersApi.getUserByUsername(value)

          if (String(this.id) === String(userVerified.id)) { // system found the same user
            return true
          } else {
            return false
          }
        } catch (err) {
          return true
        }
      }
    })

    this.loaded = true
  },
  methods: {
    async save () {
      if (await this.$validator.validateAll()) {
        this.$emit('change', this.user.username, this.firstName, this.lastName)
        this.user = await UsersApi.updateUser(this.id, this.user)
        this.$store.dispatch('refreshProfile')
        this.$parent.close()
        this.$buefy.toast.open({
          message: 'Profile Updated',
          type: 'is-success'
        })
      }
    },
    cleanCache () {
      this.$buefy.dialog.confirm({
        message: 'This will clean your user cache. The page will reload and you will lose any unsaved data. <p>Are you sure to continue?</p>',
        onConfirm: () => {
          window.localStorage.setItem(this.username, JSON.stringify({}))
          this.$parent.close()
          this.$buefy.toast.open('Cache cleaned for user: ' + this.username)
          this.$router.go(0)
        }
      })
    }
  }
}

</script>
