import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async getInvoices () {
    let response = await Axios.get(url('/api/v1/invoices'), options())

    return response.data
  },

  async getInvoicesByLicenseeId (licenseeId) {
    let response = await Axios.get(url(`/api/v1/invoices/licensee/${licenseeId}`), options())

    return response.data
  },

  async getInvoicesReview (invoiceId) {
    let response = await Axios.get(url(`/api/v1/invoices/reviews/${invoiceId}`), options())

    return response.data
  },

  async createInvoice (invoice) {
    let response = await Axios.post(url('/api/v1/invoices'), invoice, options())

    return response.data
  },

  async createInvoiceReview (invoiceReview) {
    let response = await Axios.post(url('/api/v1/invoices/review'), invoiceReview, options())

    return response.data
  },

  async getInvoiceById (id) {
    let response = await Axios.get(url(`/api/v1/invoices/${id}`), options())

    return response.data
  },

  async getLastInvoiceNumber () {
    let response = await Axios.get(url('/api/v1/invoices/lastNumber'), options())

    return response.data
  },

  async updateInvoice (id, invoice) {
    let response = await Axios.put(url(`/api/v1/invoices/${id}`), invoice, options())

    return response.data
  }
}
