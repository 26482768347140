import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async getUsers () {
    let response = await Axios.get(url('/api/v1/users'), options())

    return response.data
  },

  async getUserById (id) {
    let response = await Axios.get(url(`/api/v1/users/${id}`), options())

    return response.data
  },

  async getUsersByLicenseeId (licenseeId) {
    let response = await Axios.get(url(`/api/v1/users/licensees/${licenseeId}`), options())

    return response.data
  },

  async getUserByUsername (username) {
    let response = await Axios.get(url(`/api/v1/users/username/${username}`), options())

    return response.data
  },

  async getUserByYubikeySerial (serial) {
    let response = await Axios.get(url(`/api/v1/users/yubikey/${serial}`), options())

    return response.data
  },

  async updateUser (id, user) {
    let response = await Axios.put(url(`/api/v1/users/${id}`), user, options())

    return response.data
  },

  async updateUserForgotPassword (id, user) {
    let response = await Axios.put(url(`/api/v1/users/forgotpassword/${id}`), user, options())

    return response.data
  },

  async updateUserYubikey (id, user) {
    let response = await Axios.put(url(`/api/v1/users/yubikey/${id}`), user, options())

    return response.data
  },

  async createUser (user) {
    let response = await Axios.post(url('/api/v1/users'), user, options())

    return response.data
  },

  async deleteUser (id) {
    let response = await Axios.delete(url(`/api/v1/users/${id}`), options())

    return response.data
  },

  async deactivateUsersByLicenseeId (licenseeId) {
    await Axios.delete(url(`/api/v1/users/delete/licensee/${licenseeId}`), options())
  }

}
