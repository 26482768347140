import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async login (username, password, secretKey) {
    let response = await Axios.post(url('/api/v1/account/actions/login'), {
      username: username,
      password: password,
      secretKey: secretKey
    }, options())

    return response.data
  },

  async loginAsUser (userId) {
    let response = await Axios.post(url('/api/v1/users/login-as-user'), {
      userId: userId
    }, options())

    return response.data
  },

  async getProfile () {
    let response = await Axios.get(url('/api/v1/account'), options())

    return response.data
  },

  // Yubikey
  async getSecretKeyRegister () {
    let response = await Axios.get(url('/api/v1/account/u2f/register'), options())
    return response
  },

  async postSecretKeyRegister (registerPackage) {
    let response = await Axios.post(url('/api/v1/account/u2f/register'), registerPackage, options())

    return response.data
  },

  async getSecretKeyLogin (id) {
    let response = await Axios.get(url(`/api/v1/account/u2f/login/${id}`), options())
    return response
  },

  async postSecretKeyLogin (loginPackage) {
    let response = await Axios.post(url('/api/v1/account/u2f/login'), loginPackage, options())

    return response.data
  },

  // Yubikey OTP
  async verifyOtpKey (otpKey) {
    let response = await Axios.post(url('/api/v1/account/otp/verify'), otpKey, options())
    return response.data
  }
}
