import Store from '../store'

export function options () {
  const token = Store.state.token

  if (!token) {
    //  console.error('No API token is available!')
  }

  return {
    auth: {
      username: token,
      password: token
    }
  }
}

export function url (path) {
  const base = process.env.VUE_APP_API_BASE

  if (!base) {
    console.error('No API base is available!')
  }

  return `${base}${path}`
}
