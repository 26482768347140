import Vue from 'vue'
import Router from 'vue-router'
import Store from './store'
import Login from './views/Login.vue'

Vue.use(Router)

// const requireUser = (to, from, next) => {
//   if (Store.state.profile) {
//     next()
//   } else {
//     next({ name: 'login' })
//   }
// }

const requireAdminUser = (to, from, next) => {
  if (Store.state.profile && Store.state.profile.hasJfeAdminPermission) {
    next()
  } else {
    window.sessionStorage.setItem('redirectURL', window.location.pathname)
    next({
      name: 'home'
    })
  }
}
const requireRoyaltyUser = (to, from, next) => {
  if (Store.state.profile && (Store.state.profile.hasJfeRoyaltyTrackerPermission || Store.state.profile.hasLicenseeRoyaltyTrackerPermission || Store.state.profile.hasJfeAdminPermission)) {
    next()
  } else {
    window.sessionStorage.setItem('redirectURL', window.location.pathname)
    next({
      name: 'home'
    })
  }
}
const requireDatasheetUser = (to, from, next) => {
  if (Store.state.profile && (Store.state.profile.hasJfeDataManagerPermission || Store.state.profile.hasJfeAdminPermission)) {
    next()
  } else {
    window.sessionStorage.setItem('redirectURL', window.location.pathname)
    next({
      name: 'home'
    })
  }
}

// automatically redirect single-app users on login
// const dashboardRedirect = (to, from, next) => {
//   if (Store.state.profile) {
//     // has BOTH Royalty and Datasheet
//     if ((Store.state.profile.hasJfeRoyaltyTrackerPermission || Store.state.profile.hasLicenseeRoyaltyTrackerPermission) && (Store.state.profile.hasJfeDataManagerPermission || Store.state.profile.hasLicenseeTechnicalDataPermission)) {
//       next()
//     } else
//     // has Royalty but not Datasheet
//     if ((Store.state.profile.hasJfeRoyaltyTrackerPermission || Store.state.profile.hasLicenseeRoyaltyTrackerPermission) && (!Store.state.profile.hasJfeDataManagerPermission || !Store.state.profile.hasLicenseeTechnicalDataPermission)) {
//       next({
//         name: 'royalty_tracker_projects'
//       })
//       Store.commit('setApp', 'royalty')
//     } else
//     // has Datasheet but not Royalty
//     if ((!Store.state.profile.hasJfeRoyaltyTrackerPermission || !Store.state.profile.hasLicenseeRoyaltyTrackerPermission) && (Store.state.profile.hasJfeDataManagerPermission || Store.state.profile.hasLicenseeTechnicalDataPermission)) {
//       next({
//         name: 'datasheet_generator'
//       })
//       Store.commit('setApp', 'datasheet')
//     } else {
//       next()
//     }
//   } else {
//     next({
//       name: 'login'
//     })
//   }
// }

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [{
    name: 'home',
    path: '/',
    // beforeEnter: dashboardRedirect,
    // component: Dashboard,
    component: Login,
    meta: {
      layout: 'external',
      width: 'wide'
    }
  },
  {
    name: 'login',
    path: '/login',
    meta: {
      layout: 'external',
      width: 'wide'
    },
    component: Login
  },
  {
    name: 'login_recovery',
    path: '/login/recovery/:username/:accountHash',
    meta: {
      layout: 'external',
      parent: 'login',
      icon: 'building'
    },
    component: () => import('@/views/LoginRecovery.vue')
  },
  {
    name: 'logout',
    path: '/logout',
    meta: {
      layout: 'external'
    },
    async beforeEnter (to, from, next) {
      await Store.dispatch('logout')
      next({
        name: 'login'
      })
    }
  },
  {
    name: 'manage_offices',
    path: '/manage/offices',
    meta: {
      layout: 'manage',
      title: 'JFE Offices',
      type: 'JFE Office',
      icon: 'building'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/Offices.vue')
  },
  {
    name: 'manage_office',
    path: '/manage/offices/:id',
    meta: {
      layout: 'manage-single',
      parent: 'manage_offices',
      title: 'JFE Office',
      icon: 'building'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/singles/Office.vue')
  },
  {
    name: 'manage_users',
    path: '/manage/users',
    meta: {
      layout: 'manage',
      title: 'Users',
      type: 'User',
      icon: 'user'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/Users.vue')
  },
  {
    name: 'manage_user',
    path: '/manage/users/:id',
    meta: {
      layout: 'manage-single',
      parent: 'manage_users',
      title: 'User',
      icon: 'user'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/singles/User.vue')
  },
  {
    name: 'manage_licensees',
    path: '/manage/licensees',
    meta: {
      layout: 'manage',
      title: 'Licensees',
      type: 'Licensee',
      icon: 'id-card'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/Licensees.vue')
  },
  {
    name: 'manage_licensee',
    path: '/manage/licensees/:id',
    meta: {
      layout: 'manage-single',
      parent: 'manage_licensees',
      title: 'Licensee',
      icon: 'id-card'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/singles/Licensee.vue')
  },
  {
    name: 'manage_connections',
    path: '/manage/connections',
    meta: {
      layout: 'manage',
      title: 'Connections',
      type: 'Connection',
      icon: 'plug'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/Connections.vue')
  },
  {
    name: 'manage_connection',
    path: '/manage/connections/:id',
    meta: {
      layout: 'manage-single',
      parent: 'manage_connections',
      title: 'Connection',
      icon: 'plug'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/singles/Connection.vue')
  },

  {
    name: 'manage_materials',
    path: '/manage/materials',
    meta: {
      layout: 'manage',
      title: 'Materials',
      type: 'Material',
      icon: 'grip-horizontal'
    },
    beforeEnter: requireDatasheetUser,
    component: () => import('@/views/_manage/Materials.vue')
  },
  {
    name: 'manage_material',
    path: '/manage/materials/:id',
    meta: {
      layout: 'manage-single',
      parent: 'manage_materials',
      title: 'Material',
      icon: 'grip-horizontal'
    },
    beforeEnter: requireDatasheetUser,
    component: () => import('@/views/_manage/singles/Material.vue')
  },
  {
    name: 'manage_reports',
    path: '/manage/reports',
    meta: {
      title: 'Reports',
      type: 'Report',
      icon: 'file-excel'
    },
    beforeEnter: requireAdminUser,
    component: () => import('@/views/_manage/Reports.vue')
  },

  /// /////////// ROYALTY TRACKER //////////////

  {
    name: 'royalty_tracker_projects',
    path: '/royalty_tracker/projects',
    meta: {
      width: 'wide'
    },
    beforeEnter: requireRoyaltyUser,
    component: () => import('@/views/royalty_tracker/Projects.vue')
  },
  {
    name: 'royalty_tracker_project',
    path: '/royalty_tracker/project/:type/:id',

    beforeEnter: requireRoyaltyUser,
    component: () => import('@/views/royalty_tracker/Project.vue')
  },
  {
    name: 'royalty_tracker_invoices',
    path: '/royalty_tracker/invoices',
    meta: {
      width: 'wide'
    },
    beforeEnter: requireRoyaltyUser,
    component: () => import('@/views/royalty_tracker/Invoices.vue')
  },
  {
    name: 'royalty_tracker_invoice',
    path: '/royalty_tracker/invoices/:invoiceId/:licenseeId',
    meta: {
      layout: 'external'
    },
    beforeEnter: requireRoyaltyUser,
    component: () => import('@/views/royalty_tracker/Invoice.vue')
  },

  /// /////////// DATASHEETS //////////////

  {
    name: 'datasheet_generator',
    path: '/datasheet_generator',

    component: () => import('@/views/datasheet_generator/Generator.vue')
  },

  /// /////////// GAUGE SHEETS //////////////

  {
    name: 'inspection_sheets',
    path: '/inspection_sheets',

    component: () => import('@/views/inspection_sheets/Generator.vue')
  },

  /// /////////// BLANKING DIMENSIONS //////////////

  {
    name: 'blanking_dimensions',
    path: '/blanking_dimensions',

    component: () => import('@/views/blanking_dimensions/Generator.vue')
  },

  /// /////////// TECHNICAL DOCUMENTS //////////////

  {
    name: 'technical_documents',
    path: '/technical_documents',

    component: () => import('@/views/technical_documents/Documents.vue')
  },
  {
    name: 'technical_documents_download',
    path: '/technical_documents/:id',

    component: () => import('@/views/technical_documents/Documents.vue')
  }
  ],
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  }
})
