import Vue from 'vue'
import Vuex from 'vuex'

import AccountApi from '@/apis/AccountApi'
import AdminApi from '@/apis/AdminApi'
import ProjectsApi from '@/apis/ProjectsApi'
import FilesApi from '@/apis/FilesApi'
import UsersApi from '@/apis/UsersApi'
import InvoicesApi from '@/apis/InvoicesApi'

Vue.use(Vuex)

const AuthTokenKey = 'jfe.auth.token.v1'

export default new Vuex.Store({
  state: {
    token: null,
    profile: null,
    loginResponse: null,
    app: null,
    title: '',
    users: [],
    offices: [],
    licensees: [],
    licenseeConnections: [],
    connections: [],
    connectionsLight: [],
    connectionGrades: [],
    connectionGradesHC: [],
    projects: [],
    files: [],
    technicalDocuments: [],
    fileCategories: [],
    fileDownloads: [],
    fileRevisions: [],
    invoices: [],
    datasheetLanguages: [],
    inspectionSheetFields: []
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setApp (state, app) {
      state.app = app
    },
    setProfile (state, profile) {
      state.profile = profile
    },
    setLoginResponse (state, response) {
      state.loginResponse = response
    },
    setTitle (state, title) {
      state.title = title
    },
    setUsers (state, users) {
      state.users = users
    },
    setOffices (state, offices) {
      state.offices = offices
    },
    setLicensees (state, licensees) {
      state.licensees = licensees
    },
    setLicenseeConnections (state, licenseeConnections) {
      state.licenseeConnections = licenseeConnections
    },
    setConnections (state, connections) {
      state.connections = connections
    },
    setConnectionsLight (state, connectionsLight) {
      state.connectionsLight = connectionsLight
    },
    setConnectionGrades (state, connectionGrades) {
      state.connectionGrades = connectionGrades
    },
    setConnectionGradesHC (state, connectionGradesHC) {
      state.connectionGradesHC = connectionGradesHC
    },
    setProjects (state, projects) {
      state.projects = projects
    },
    setInvoices (state, invoices) {
      state.invoices = invoices
    },
    setDatasheetLanguages (state, datasheetLanguages) {
      state.datasheetLanguages = datasheetLanguages
    },
    setFiles (state, files) {
      state.files = files
    },
    setTechnicalDocuments (state, technicalDocuments) {
      state.technicalDocuments = technicalDocuments
    },
    setFileCategories (state, fileCategories) {
      state.fileCategories = fileCategories
    },
    setFileDownloads (state, fileDownloads) {
      state.fileDownloads = fileDownloads
    },
    setFileRevisions (state, revisions) {
      state.fileRevisions = revisions
    },
    setInspectionSheetFields (state, inspectionSheetFields) {
      state.inspectionSheetFields = inspectionSheetFields
    }
  },
  actions: {
    async init (context) {
      const token = window.sessionStorage.getItem(AuthTokenKey)

      if (token) {
        context.commit('setToken', token)
        context.commit('setProfile', await AccountApi.getProfile())
      } else {
        context.commit('setProfile', 'guest')
      }
    },
    async login (context, params) {
      const result = await AccountApi.login(params.username, params.password, params.secretKey)

      if (result.profile.u2fEnabled) {
        context.commit('setLoginResponse', result)
      } else {
        if (result.token) {
          window.sessionStorage.setItem(AuthTokenKey, result.token)

          context.commit('setToken', result.token)
          context.commit('setProfile', result.profile)
        }
      }
    },
    async loginAsUser (context, params) {
      const result = await AccountApi.loginAsUser(params.userId)
      window.sessionStorage.setItem(AuthTokenKey, result.token)
      context.commit('setToken', result.token)
      context.commit('setProfile', result.profile)
    },
    async logout (context) {
      context.commit('setProfile', 'guest')
      context.commit('setToken', null)

      window.sessionStorage.removeItem(AuthTokenKey)
    },
    async refreshLoginU2F (context, req) {
      if (req.token) {
        window.sessionStorage.setItem(AuthTokenKey, req.token)

        context.commit('setToken', req.token)
        context.commit('setProfile', req.profile)
      }
    },
    async refreshProfile (context) {
      context.commit('setProfile', await AccountApi.getProfile())
    },
    async refreshUsers (context) {
      context.commit('setUsers', await UsersApi.getUsers())
    },
    async refreshOffices (context) {
      context.commit('setOffices', await AdminApi.getOffices())
    },
    async refreshLicensees (context, params) {
      context.commit('setLicensees', await AdminApi.getLicensees(params))
    },
    async refreshLicenseeConnections (context) {
      context.commit('setLicenseeConnections', await AdminApi.getLicenseeConnections())
    },
    async refreshConnections (context) {
      context.commit('setConnections', await AdminApi.getConnections())
    },
    async refreshConnectionsLight (context, params) {
      context.commit('setConnectionsLight', await AdminApi.getConnectionsLight(params.hasImages, params.hasSizes, params.hasWeights))
    },
    async refreshConnectionGrades (context) {
      context.commit('setConnectionGrades', await AdminApi.getConnectionGrades())
    },
    async refreshConnectionGradesWithDeleted (context) {
      context.commit('setConnectionGrades', await AdminApi.getConnectionGradesWithDeleted())
    },
    async refreshConnectionGradesHC (context, params) {
      context.commit('setConnectionGradesHC', await AdminApi.getConnectionGradeHC(params.connectionId, params.sizeId, params.weightId, params.gradeId))
    },
    async refreshProjects (context, params) {
      context.commit('setProjects', await ProjectsApi.getProjects(params))
    },
    async refreshInvoices (context) {
      context.commit('setInvoices', await InvoicesApi.getInvoices())
    },
    async refreshProjectsByOfficeUser (context, profile) {
      context.commit('setProjects', await ProjectsApi.getProjectsByOfficeUser(profile))
    },
    async refreshProjectsByLicenseeId (context, profile) {
      context.commit('setProjects', await ProjectsApi.getProjectsByLicenseeId(profile))
    },
    async refreshProjectsByLicenseeDivisions (context, profile) {
      context.commit('setProjects', await ProjectsApi.getProjectsByLicenseeDivisions(profile))
    },
    async refreshDatasheetLanguages (context) {
      context.commit('setDatasheetLanguages', await AdminApi.getDatasheetLanguages())
    },
    async refreshFiles (context) {
      context.commit('setFiles', await FilesApi.getFiles())
    },
    async refreshTechnicalDocuments (context) {
      context.commit('setTechnicalDocuments', await FilesApi.getTechnicalDocuments('all'))
    },
    async refreshFileCategories (context) {
      context.commit('setFileCategories', await FilesApi.getFileCategories())
    },
    async refreshFileDownloads (context) {
      context.commit('setFileDownloads', await FilesApi.getFileDownloads())
    },
    async refreshFileRevisions (context, fileId) {
      context.commit('setFileRevisions', await FilesApi.getFileRevisions(fileId))
    },
    async refreshInspectionSheetFields (context) {
      context.commit('setInspectionSheetFields', await AdminApi.getInspectionSheetFields())
    }
  }
})
