import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async createOffice (office) {
    let response = await Axios.post(url('/api/v1/admin/offices'), office, options())

    return response.data
  },
  async updateOffice (id, office) {
    let response = await Axios.put(url(`/api/v1/admin/offices/${id}`), office, options())

    return response.data
  },
  async getOffices () {
    let response = await Axios.get(url('/api/v1/admin/offices'), options())

    return response.data
  },
  async getOfficeById (id) {
    let response = await Axios.get(url(`/api/v1/admin/offices/${id}`), options())

    return response.data
  },
  async createConnection (connection) {
    let response = await Axios.post(url('/api/v1/admin/connections'), connection, options())

    return response.data
  },
  async createConnectionImage (id, name) {
    let response = await Axios.post(url(`/api/v1/admin/connections/${id}/image`), name, options())

    return response.data
  },
  async updateConnection (id, connection) {
    let response = await Axios.put(url(`/api/v1/admin/connections/${id}`), connection, options())

    return response.data
  },
  async updateConnectionSize (id, size) {
    let response = await Axios.put(url(`/api/v1/admin/connections/size/${id}`), size, options())

    return response.data
  },
  async deleteConnection (connectionId) {
    await Axios.delete(url(`/api/v1/admin/connections/${connectionId}`), options())
  },
  async deleteConnectionSizeWeightById (weightId) {
    await Axios.delete(url(`/api/v1/admin/connections/size/weight/${weightId}`), options())
  },
  async getConnections () {
    let response = await Axios.get(url('/api/v1/admin/connections'), options())

    return response.data
  },
  async getConnectionsLight (hasImages = false, hasSizes = false, hasWeights = false) { // Performance purpose
    let response = await Axios.get(url(`/api/v1/admin/connections/light/${hasImages}/${hasSizes}/${hasWeights}`), options())

    return response.data
  },
  async getConnectionById (id) {
    let response = await Axios.get(url(`/api/v1/admin/connections/${id}`), options())

    return response.data
  },
  async getConnectionSizesByConnectionId (connectionId) {
    let response = await Axios.get(url(`/api/v1/admin/connections/getListSizes/${connectionId}`), options())

    return response.data
  },
  async createLicensee (licensee) {
    let response = await Axios.post(url('/api/v1/admin/licensees'), licensee, options())

    return response.data
  },
  async updateLicensee (id, licensee) {
    let response = await Axios.put(url(`/api/v1/admin/licensees/${id}`), licensee, options())

    return response.data
  },
  async getLicensees (fields = null) {
    // let response = await Axios.get(url('/api/v1/admin/licensees'), options())
    let response = await Axios.request({
      method: 'get',
      url: url('/api/v1/admin/licensees'),
      params: fields,
      options: options() // XUNXO - With or without it, works fine?
    })

    return response.data
  },
  async getLicenseeById (id) {
    let response = await Axios.get(url(`/api/v1/admin/licensees/${id}`), options())

    return response.data
  },
  async createLicenseeDivision (division) {
    let response = await Axios.post(url('/api/v1/admin/licensees/divisions'), division, options())

    return response.data
  },
  async updateLicenseeDivisions (division) {
    let response = await Axios.put(url(`/api/v1/admin/licensees/divisions/${division.id}`), division, options())

    return response.data
  },
  async getLicenseeDivisions (licenseeId) {
    let response = await Axios.get(url(`/api/v1/admin/licensees/${licenseeId}/divisions`), options())

    return response.data
  },
  async deleteLicenseeDivision (licenseeId, divisionId) {
    await Axios.delete(url(`/api/v1/admin/licensees/${licenseeId}/divisions/${divisionId}`), options())
  },
  async getLicenseeConnections (fields) {
    // let response = await Axios.get(url('/api/v1/admin/licensees/connections'), options())
    let response = await Axios.request({
      method: 'get',
      url: url('/api/v1/admin/licensees/connections'),
      params: fields,
      options: options() // XUNXO - With or without it, works fine?
    })

    return response.data
  },
  async getLicenseeConnectionsByLicenseeId (fields) {
    let response = await Axios.request({
      method: 'get',
      url: url(`/api/v1/admin/licensees/${fields.id}/connections`),
      params: fields,
      options: options()
    })
    // let response = await Axios.get(url(urlDest), params, options())

    return response.data
  },
  async createLicenseeConnection (licenseeId, licenseeConnection) {
    let response = await Axios.post(url(`/api/v1/admin/licensees/${licenseeId}/connections`), licenseeConnection, options())

    return response.data
  },
  async updateLicenseeConnection (licenseeId, licenseeConnectionId, fields) {
    let response = await Axios.put(url(`/api/v1/admin/licensees/${licenseeId}/connections/${licenseeConnectionId}`), fields, options())

    return response.data
  },
  async getConnectionGrades () {
    let response = await Axios.get(url('/api/v1/admin/grades'), options())

    return response.data
  },
  async getConnectionGradesWithDeleted () {
    let response = await Axios.get(url('/api/v1/admin/grades/deleted'), options())

    return response.data
  },
  async getConnectionGradeHC (connectionId, sizeId, weightId, gradeId) {
    let response = await Axios.get(url(`/api/v1/admin/grades/${connectionId}/${sizeId}/${weightId}/${gradeId}`), options())

    return response.data
  },
  async updateGradeHC (id, fields) {
    let response = await Axios.put(url(`/api/v1/admin/grades/hc/${id}`), fields, options())

    return response.data
  },
  async deleteGradeHC (id, fields) {
    await Axios.delete(url(`/api/v1/admin/grades/hc/${id}`), options())
  },
  async createGradeHC (grade) {
    let response = await Axios.post(url('/api/v1/admin/grades/hc'), grade, options())

    return response.data
  },
  async getDatasheetLanguages () {
    let response = await Axios.get(url('/api/v1/admin/datasheetLanguages'), options())

    return response.data
  },
  async getInspectionSheetFields () {
    let response = await Axios.get(url('/api/v1/admin/inspectionsheet/fields'), options())

    return response.data
  },
  async getGradeById (id) {
    let response = await Axios.get(url(`/api/v1/admin/grades/${id}`), options())

    return response.data
  },
  async createGrade (grade) {
    let response = await Axios.post(url('/api/v1/admin/grades'), grade, options())

    return response.data
  },
  async deleteGrade (id) {
    await Axios.delete(url(`/api/v1/admin/grades/${id}`), options())
  },
  async updateGrade (id, grade) {
    let response = await Axios.put(url(`/api/v1/admin/grades/${id}`), grade, options())

    return response.data
  },
  // new
  async getGradeColorsById (id) {
    let response = await Axios.get(url(`/api/v1/admin/grades/colors/${id}`), options())

    return response.data
  },
  // new
  async updateGradeColors (id, gradeStripes) {
    let response = await Axios.put(url(`/api/v1/admin/grades/colors/${id}`), gradeStripes, options())

    return response.data
  },
  // new
  async getGradeVisibiltyColumn (weightId) {
    let response = await Axios.get(url(`/api/v1/admin/grades/visibility/${weightId}`), options())

    return response.data
  },
  // new
  async updateGradeVisibilityColumn (weightId, hiddenGrades) {
    let response = await Axios.put(url(`/api/v1/admin/grades/visibility/${weightId}`), hiddenGrades, options())

    return response.data
  },
  async getGradeSpDriftVisibiltyColumn (weightId) {
    let response = await Axios.get(url(`/api/v1/admin/grades/spDrift/visibility/${weightId}`), options())

    return response.data
  },
  async updateGradeSpDriftVisibilityColumn (weightId, showSpDrifts) {
    let response = await Axios.put(url(`/api/v1/admin/grades/spDrift/visibility/${weightId}`), showSpDrifts, options())

    return response.data
  },
  async getCouplingValues (weightId) {
    let response = await Axios.get(url(`/api/v1/admin/coupling/${weightId}`), options())

    return response.data
  },
  async makeDumpFiles (action) {
    let response = await Axios.get(url(`/api/v1/mobile/action/${action}`), options())

    return response.data
  },
  async exportReport (connection, report) {
    const response = await Axios.get(url(`/api/v1/admin/report/${connection}/${report}`), options())

    return response.data
  },
  async importReport (connectionId, reportType, data) {
    const response = await Axios.post(url(`/api/v1/admin/report/import/${connectionId}/${reportType}`), data, options())

    return response.data
  }
}
