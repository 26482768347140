<template>
  <b-dropdown
    v-if="profile && (profile.hasJfeAdminPermission || profile.hasJfeDataManagerPermission)"
    :mobile-modal="false"
    :position="position"
    :class="dropdownClass">
    <a slot="trigger" class="navbar-item">
      <b-icon icon="cog" />
      <span v-if="label">
        {{ label }}
      </span>
    </a>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission" has-link>
      <router-link :to="{ name: 'manage_offices' }">
        <div class="media">
          <div class="media-content">
            JFE Offices
          </div>
          <b-icon class="media-right" icon="building" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission" has-link>
      <router-link :to="{ name: 'manage_licensees' }">
        <div class="media">
          <div class="media-content">
            Licensees
          </div>
          <b-icon class="media-right" icon="id-card" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission" has-link>
      <router-link :to="{ name: 'manage_users' }">
        <div class="media">
          <div class="media-content">
            Users
          </div>
          <b-icon class="media-right" icon="user" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission" has-link>
      <router-link :to="{ name: 'manage_connections' }">
        <div class="media">
          <div class="media-content">
            Connections
          </div>
          <b-icon class="media-right" icon="plug" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission || profile.hasJfeDataManagerPermission" has-link>
      <router-link :to="{ name: 'manage_materials' }">
        <div class="media">
          <div class="media-content">
            Materials
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.hasJfeAdminPermission" has-link>
      <router-link :to="{ name: 'manage_reports' }">
        <div class="media">
          <div class="media-content">
            Reports
          </div>
          <b-icon class="media-right" icon="file-excel" size="is-small" />
        </div>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.username === 'vini@jw.solar'" has-link>
      <a @click.capture="makeDumpFiles">
        <div class="media">
          <div class="media-content">
            Make Dump Files
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </a>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.username === 'vini@jw.solar'" has-link>
      <a @click.capture="makeConversionCouplings">
        <div class="media">
          <div class="media-content">
            Make Conversion Couplings
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </a>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.username === 'vini@jw.solar'" has-link>
      <a @click.capture="makeConversionKsi">
        <div class="media">
          <div class="media-content">
            Make Conversion Ksi
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </a>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.username === 'vini@jw.solar'" has-link>
      <a @click.capture="makeConversionDivisions">
        <div class="media">
          <div class="media-content">
            Make Conversion Divisions
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </a>
    </b-dropdown-item>
    <b-dropdown-item v-if="profile.username === 'vini@jw.solar'" has-link>
      <a @click.capture="makeFixDivisions">
        <div class="media">
          <div class="media-content">
            Fix Divisions
          </div>
          <b-icon class="media-right" icon="grip-horizontal" size="is-small" />
        </div>
      </a>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import AdminApi from '@/apis/AdminApi'

export default {
  props: {
    classes: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dropdownClass: this.classes
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },
  methods: {
    async makeDumpFiles () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeDumpFiles')
      loadingComponent.close()
    },
    async makeConversionCouplings () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeConversionCouplings')
      loadingComponent.close()
    },
    async makeConversionKsi () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeConversionKsi')
      loadingComponent.close()
    },
    async makeConversionDivisions () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeConversionDivisions')
      loadingComponent.close()
    },
    async makeFixDivisions () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeFixDivisions')
      loadingComponent.close()
    }
  }
}
</script>
